
/*
 * INITIALIZE CITIES
 */


function initCities(state, city, state_id, city_id)
{
    var $state   = $(state);
    var $city    = $(city);
    var state_id = state_id == '' || state_id == undefined ? 18   : state_id;
    var city_id  = city_id  == '' || city_id  == undefined ? 2805 : city_id;

    $state.on('change', function() {
        var state_id = $(this).val();

        if(state_id == ""){
            $city.html('<option value="">Você precisa escolher um estado</option>');
            return;
        } else{
            $city.html('<option value="">Carregando..</option>');
        }

        $.get(baseurl + "api/v1/cities/" + state_id, {}, function(data, textStatus) {
            if(data != null) popularListaDeCidades(data);
        }, "json");
    });

    $state.find('option[value="' + state_id  + '"]').attr("selected", "selected");
    $state.change();



    function popularListaDeCidades(cidades, idCidade) {
        var options = '<option value="">Escolha uma cidade</option>';
        $.each(cidades, function(index, cidade){
            if(idCidade == cidade['id'])
                options += '<option selected="selected" value="' + cidade['id'] + '">' + cidade['name'] + '</option>';
            else
                options += '<option value="' + cidade['id'] + '">' + cidade['name'] + '</option>';
        });
        $city.html(options);

        if(city_id != '' || city_id != undefined){
            $city.find('option[value="' + city_id  + '"]').attr("selected", "selected");
            city_id = '';
        }
    }
}