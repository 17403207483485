
$(document).ready(function() {

    console.log('start site: ' + pageMenu);

    $("#section-menu li.active").removeClass("active");
    $("#section-menu li." + pageMenu).addClass("active");

    if(pageSubMenu != ''){
        $("#box-header-menu li.active").removeClass("active");
        $("#box-header-menu li." + pageSubMenu).addClass("active");
    }
});
