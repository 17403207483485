
// @codekit-prepend "../plugins/validation-1.13.1/jquery.validate.js";
// @codekit-prepend "../plugins/validation-1.13.1/additional-methods";
// @codekit-prepend "../plugins/validation-1.13.1/localization/messages_pt_BR.js";




// ===============================
// Validation
// ===============================

function initializeContato(){

    console.log("Initialize Contato..");

    jQuery.validator.setDefaults({
        debug: true,
        success: "valid"
    });

    var form = $("#form");
    form.validate({
        /*
         Lista de métodos de validação do plugin
         required – Makes the element required.
         remote – Requests a resource to check the element for validity.
         minlength – Makes the element require a given minimum length.
         maxlength – Makes the element require a given maxmimum length.
         rangelength – Makes the element require a given value range.
         min – Makes the element require a given minimum.
         max – Makes the element require a given maximum.
         range – Makes the element require a given value range.
         email – Makes the element require a valid email
         url – Makes the element require a valid url
         date – Makes the element require a date.
         dateISO – Makes the element require an ISO date.
         number – Makes the element require a decimal number.
         digits – Makes the element require digits only.
         creditcard – Makes the element require a credit card number.
         equalTo – Requires the element to be the same as another one
         */
        //inicie sua validação informando as regras para validação.
        rules: {
            "form[name]"   : "required",
            "form[email]"  : {
                    required : true,
                    email    : true
            },
            "form[phone]"  : "required",
            "form[city]"   : "required",
            "form[state]"  : "required",
            "form[message]": "required",
        },

        errorElement: 'span',
        messages: {
            "form[name]": {
                required: "Digite seu nome"
            },

            "form[email]": {
                required: "Digite seu E-mail",
                email:"E-mail inválido"
            },

            "form[phone]": {
                required: "Digite seu telefone"
            },

            "form[city]": {
                required: "Digite nome da sua cidade"
            },

            "form[state]": {
                required: "Digite nome do seu estado"
            },

            "form[message]": {
                required: "Digite sua mensagem"
            }
        },
        submitHandler: function() {
            sendMail();
        }

    });
}


// ===============================
// SendMail
// ===============================

function sendMail()
{
    change_status(0);

    var action = baseurl + "contato";
    var data = $("#form").serialize();

    var jqxhr = $.post(action, $("#form").serialize() );
    jqxhr.done(function(data) {
        if(data == "ok")    change_status(1);
        if(data == "error") change_status(-1);
    });

    jqxhr.fail(function() {     console.log("- Fail Form");  change_status(-1);  })
    jqxhr.complete(function() { console.log("- Complete");   canSendMail = true; })
}


// ===============================
// ChangeStatus
// ===============================

function change_status(arg){
    $(".status.fail").css("display","none");
    $(".status.wait").css("display","none");
    $(".status.done").css("display","none");

    if(arg == -1){
        $(".status.fail").stop();
        $(".status.fail").fadeIn(300).delay(10000).fadeOut(300);
        $("#form button[type=submit]").removeAttr("disabled");
    }
    else if(arg == 0){
        $(".status.wait").stop();
        $(".status.wait").fadeIn(300).delay(10000).fadeOut(300);
        $("#form button[type=submit]").attr("disabled", "disabled");
    }

    else if(arg == 99){
        $(".status.invalidate").stop();
        $(".status.invalidate").fadeIn(300).delay(10000).fadeOut(300);
    }else

    if(arg == 1){
        $(".status.done").stop();
        $(".status.done").fadeIn(300).delay(10000).fadeOut(300);
        $("#form button[type=submit]").removeAttr("disabled");
        clean_form();
    }

    //$(".status").delay(10000).fadeOut();
}



// ===============================
// ClearForm
// ==============================

function clean_form(){
    $(':input','#form')
        .not(':button, :submit, :reset, :hidden')
        .val('')
        .removeAttr('checked')
        .removeAttr('selected');
}

